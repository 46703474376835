button {
  @apply px-5 bg-[var(--theme-gray)] rounded;
}

button[type="primary"] {
  @apply bg-[var(--theme-primary)] text-white;
}

button[is-plain="true"] {
  @apply bg-transparent border-solid border-[2px] border-[var(--theme-gray)];
}

button[ype="primary"][is-plain="true"] {
  @apply border-primary;
}