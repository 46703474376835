input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  position: relative;
  display: flex;
  align-items: center;
}

input[type='checkbox'] + label:hover:before {
  border: 2px solid #63c468;
}

input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  margin-right: 12px;
  width: 22px;
  height: 22px;
  background: #fff;
  border: 1px solid #d7d8da;
  border-radius: 6px;
}

input[type='checkbox'] + label svg {
  position: absolute;
  left: -4px;
  display: none;
}

input[type='checkbox']:checked + label svg {
  display: block;
  color: #63c468;
}