.wrapper {
  @apply mx-auto px-3 max-w-[1520px];
}

.headerWrap header {
  @apply bg-opacity-80;
}

.footerWrap footer .wrapper {
  @apply pt-[128px];
}